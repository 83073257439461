import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/1',
    name: 'Homeimg',
    component: () =>
    import ( /* webpackChunkName: "Home" */ '../views/Homeimg.vue'),
    meta: {
      isShowHeader: true,
    }
  },
  {
    path: '/Home',
    name: 'Home',
    component: () =>
    import ( /* webpackChunkName: "Home" */ '../views/Home.vue'),
    meta: {
      isShowHeader: true,
    }
  },
  {
   path:'/prefacehall',
   name:'PrefaceHall',
   component: () =>
    import ( /* webpackChunkName: "PrefaceHall" */ '../views/PrefaceHall.vue'),
    meta: {
      isShowHeader: true
    }
  },
  {
    path:'/CivilAir',
    name:'CivilAir',
    component: () =>import ( /* webpackChunkName: "CivilAir" */ '../views/CivilAir/CivilAir.vue'),
     meta: {isShowHeader: true},
   },
   {
    path:'/airPolice', //二级路由的详情页信息路由
    name:'airPolice',
    component: () =>import ( /* webpackChunkName: "airPolice" */ '../views/CivilAir/child/airPolice.vue'),
    meta: {
      keepAlive: true // 需要被缓存
  }
   },
   {
    path: '/airPolicelist',
    name:'airPolicelist',
    component: () =>import ( /* webpackChunkName: "airPolice" */ '../views/CivilAir/child/airPolicelist.vue'),
  },
   {
    path:'/DisasterPrevention',
    name:'DisasterPrevention',
    component: () =>import ( /* webpackChunkName: "airPolice" */ '../views/DisasterPrevention'),
    meta: {isShowHeader: true},
   }
   ,
   {
    path:'/ReviewProspect',
    name:'ReviewProspect',
    component: () =>import ( /* webpackChunkName: "airPolice" */ '../views/ReviewProspect'),
    meta: {isShowHeader: true},
   },

// 儿童路由
   {
    path: '/2',
    name: 'childHome',
    component: () =>
    import ( /* webpackChunkName: "Home" */ '../views/HomeChild.vue'),
    meta: {
      isShowHeader: true,
    }
  },
  {
    path: '/childrenPreface',
    name: 'childrenPreface',
    component: () =>
    import ( /* webpackChunkName: "Home" */ '../views/childrenHome/towchidlren/childrenPreface.vue'),
    
  },
  {
    path:'/PrefaceHallchild',
    name:'PrefaceHallchild',
    component: () =>
     import ( /* webpackChunkName: "PrefaceHall" */ '../views/childrenHome/towchidlren/PrefaceHallchild.vue'),
 
   },
   {
    path:'/CivilAirchild',
    name:'CivilAirchild',
    component: () =>import ( /* webpackChunkName: "CivilAir" */ '../views/childrenHome/towchidlren/CivilAir/CivilAirchild.vue'),
     meta: {isShowHeader: true},
   },
   {
    path:'/airPolicechild', //二级路由的详情页信息路由
    name:'airPolicechild',
    component: () =>import ( /* webpackChunkName: "airPolice" */ '../views/childrenHome/towchidlren/CivilAir/child/airPolicechild.vue'),
  
   },
   {
    path: '/airPolicelistchild',
    name:'airPolicelistchild',
    component: () =>import ( /* webpackChunkName: "airPolice" */ '../views/childrenHome/towchidlren/CivilAir/child/airPolicelistchild.vue'),
  },
   {
    path:'/DisasterPreventionchild',
    name:'DisasterPreventionchidl',
    component: () =>import ( /* webpackChunkName: "airPolice" */ '../views/childrenHome/towchidlren/DisasterPreventionchidl'),
    meta: {isShowHeader: true},
   }
   ,
   {
    path:'/ReviewProspectchild',
    name:'ReviewProspectchild',
    component: () =>import ( /* webpackChunkName: "airPolice" */ '../views/childrenHome/towchidlren/ReviewProspectchild'),
    meta: {isShowHeader: true},
   },

   
   
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes

})

export default router
