import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import flexible from './assets/js/flexible'
import VueTouch from 'vue-touch'
Vue.use(VueTouch, { name: 'v-touch' })
VueTouch.config.swipe = {
  threshold: 10 //手指左右滑动触发事件距离
}

Vue.config.productionTip = false
Vue.prototype.$baseurl = 'https://weimai-mf-admin.oss-cn-shanghai.aliyuncs.com'
new Vue({
  router,
  store,
  flexible,
  render: h => h(App)
}).$mount('#app')
