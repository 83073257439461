<template>
  <div :id=" aaa == 1 ? 'app' : 'app2' "  >
      <router-view   />
  </div>
</template>
<script>
import headers from './components/header.vue'
export default {
  components: { headers},
  data(){
    return{
      aaa:1
    }

    
  },
   created(){
 
   },
   methods:{
   
   },

}
</script>

<style lang="scss" >
* {
  margin: 0;
  padding: 0;
}

body ,html{
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
 overflow-y:auto ;
 background: rgb(30, 57, 98);
}
#app2 {
  width: 100%;
  height: 100%;
 overflow-y:auto ;
 background: linear-gradient(rgb(31, 46, 53), rgb(92, 103, 106));

}
</style>
