import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeindexchild:null,
    towactivechild:null,
    activeindex:null,
    towactive:null,
    routerId:null,
    routerId2:null
  },
  mutations: {
    addactive(state,num){
      state.activeindexchild = num

    },
    addactive1(state,num1){
 
      state.towactivechild = num1
    },

    addactive2(state,num){
      state.activeindex = num

    },
    addactive3(state,num1){
 
      state.towactive = num1
    }
  },
  actions: {
  },
  modules: {
  }
})
