<template>
  <div class="headerbox">
        <p @click="toHome">上海民防科普教育馆</p> 
  </div>
</template>

<script>

export default {
  methods:{
    toHome(){
      let r = this.$route
     if(r.path == '/Home'){
       console.log('已经在首页了');
     }else{
      this.$router.push('/Home')
     }
     
    }
  }
}
</script>

<style lang="scss" >

.headerbox{
    height: 1.3rem;
    width: 100%;
    background: rgb(31, 46, 53);
    color: #fff;
    font-size: 0.44rem;
    text-align: center;
    line-height: 1.3rem;
    position: relative;
    img{
      position: absolute;
       width: 0.4rem;
       height: 0.72rem;
      top: 50%;
      transform: translateY(-50%);
      left: 0.5rem;
    }
}


</style>